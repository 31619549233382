import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppRoutes } from '../enum/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiResponse401Interceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return request.url.startsWith(environment.backendUrl)
      ? next.handle(request).pipe(
          catchError((event) => {
            if (
              event instanceof HttpErrorResponse &&
              event.status === 401 &&
              event.error.message !== 'WRONG_CREDENTIALS'
            ) {
              this.authService.logout().then(() => this.router.navigate(['/', AppRoutes.LOGIN]));
            }
            return throwError(event);
          })
        )
      : next.handle(request);
  }
}
