import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginForm, UserTokenPayload } from '@izy-barcode/types';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from '../services/modules/account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private snackBar: MatSnackBar,
    private accountService: AccountService
  ) {}

  /**
   * Login service, from site/depot/user/password data
   */
  public login(query: LoginForm): Observable<string> {
    return this.http.post<{ token: string }>(`${environment.backendUrl}/api/v1/login`, query).pipe(
      map((res: { token: string }) => {
        localStorage.setItem(environment.jwtBearerTokenKey, res.token);
        return res.token;
      }),
      // TODO : à valider
      shareReplay(),
      catchError((err: any) => {
        console.error(err);
        this.snackBar.open(`Erreur d'authentification`, 'OK', { duration: 3000 });
        return throwError(err);
      })
    );
  }

  /**
   * Logout service. Empties token stored in localStorage
   */
  public logout(saveLogout = false): Promise<void> {
    return (
      saveLogout ? this.http.post(`${environment.backendUrl}/api/v1/logout`, {}).toPromise() : Promise.resolve(null)
    ).then(() => {
      localStorage.removeItem(environment.jwtBearerTokenKey);
      this.accountService.clearCache();
    });
  }

  /**
   * True if the stored token is still valid
   */
  public isLoggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  public getUser(): UserTokenPayload | null {
    const token = localStorage.getItem(environment.jwtBearerTokenKey);

    if (token) {
      const payload = token.split('.')[1];

      return JSON.parse(atob(payload));
    }

    return null;
  }

  public updateDeposit(siteId: number, depotId: number): Promise<void> {
    return this.http
      .put<{ token: string }>(`${environment.backendUrl}/api/v1/update-deposit`, { depotId, siteId })
      .toPromise()
      .then((res) => localStorage.setItem(environment.jwtBearerTokenKey, res.token));
  }
}
