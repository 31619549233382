export enum AppRoutes {
  HOME = 'accueil',
  LOGIN = 'connexion',
  ASSIGNMENT = 'adressage',
  PICKING = 'prelevement',
  RECEIPT = 'reception',
  RETURN = 'retour',
  COUNTING = 'comptage',
  STOCK_STATUS = 'etat-stock',
  HISTORY = 'historique',
  USER = 'compte',
  USER_SETTINGS = 'configuration',
  DELIVERY = 'bl',
  QUICKSCAN = 'scan',
  BY_USER = 'by-user',
}
