import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateUserParametersForm, UserParameters } from '@izy-barcode/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentSettings$ = new BehaviorSubject<UserParameters>(null);

  constructor(private http: HttpClient) {}

  /**
   * Récupére les paramètres de l'utilisteur dans le cache
   */
  getAccountSettings(): Observable<UserParameters> {
    return this.currentSettings$.value ? this.currentSettings$.pipe(first()) : this.requestAccountSettings();
  }

  /**
   * Mise à jour des paramètres de l'utilisateur
   */
  updateAccountSettings(userSettings: UpdateUserParametersForm): Observable<UserParameters> {
    return this.http
      .post<UserParameters>(`${environment.backendUrl}/api/v1/user-settings`, userSettings)
      .pipe(tap((newUserSettings: UserParameters) => this.currentSettings$.next(newUserSettings)));
  }

  /**
   * Récupére les paramètres de l'utilisteur de l'API
   */
  private requestAccountSettings(): Observable<UserParameters> {
    return this.http
      .get<UserParameters>(`${environment.backendUrl}/api/v1/user-settings`)
      .pipe(tap((newUserSettings: UserParameters) => this.currentSettings$.next(newUserSettings)));
  }

  /**
   * Clear cached settings
   */
  public clearCache(): void {
    this.currentSettings$.next(null);
  }
}
