import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MODULES_NAMES } from '@izy-barcode/types';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppRoutes } from './core/enum/app-routes.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentThemeClass = '';
  routerSub: Subscription;

  constructor(private router: Router, private overlayContainer: OverlayContainer) {}

  ngOnInit() {
    this.routerSub = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // A chaque fin de navigation en succès, màj du thème CSS utilisé
        // 1 : Modification de la classe affectée au AppComponent
        const url = event.urlAfterRedirects;
        // Récupération du 1ere élément de la route, en gérant le leading slash
        const appRoute = url.split('/', 2)[1];
        switch (appRoute) {
          case AppRoutes.ASSIGNMENT:
            this.currentThemeClass = `${MODULES_NAMES.ASSIGNMENT}-izy-theme`;
            break;
          case AppRoutes.PICKING:
            this.currentThemeClass = `${MODULES_NAMES.PICKING}-izy-theme`;
            break;
          case AppRoutes.RECEIPT:
            this.currentThemeClass = `${MODULES_NAMES.RECEIPT}-izy-theme`;
            break;
          case AppRoutes.RETURN:
            this.currentThemeClass = `${MODULES_NAMES.RETURN}-izy-theme`;
            break;
          case AppRoutes.COUNTING:
            this.currentThemeClass = `${MODULES_NAMES.COUNTING}-izy-theme`;
            break;
          case AppRoutes.STOCK_STATUS:
            this.currentThemeClass = `${MODULES_NAMES.STOCK_STATUS}-izy-theme`;
            break;
          default:
            this.currentThemeClass = '';
            break;
        }

        // 2 : Modification de la classe affectée au Overlay container
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
          item.includes('-izy-theme')
        );
        if (themeClassesToRemove.length) {
          overlayContainerClasses.remove(...themeClassesToRemove);
        }
        if (this.currentThemeClass) {
          overlayContainerClasses.add(this.currentThemeClass);
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
