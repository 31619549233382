import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

/**
 * Module centralisant les déclarations d'icônes svg custom.
 * Importé dans le SharedModule, et disponible partout.
 */
@NgModule({
  imports: [HttpClientModule],
})
export class CustomSvgIconsModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'code_barre_ean128',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/code_barre_ean128.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'code_barre',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/code_barre.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'emplacement_sortant',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/emplacement_sortant.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'num_serie',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/num_serie.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ref_article',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/ref_article.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'colis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/formulaire/colis.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'adressage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/adressage_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comptage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/comptage_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'etat_stock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/etat_stock_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'picking',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/picking_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'receipt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/receipt_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'return',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/return_icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bursting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/burst_icon.svg')
    );
  }
}
