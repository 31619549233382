import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { AppRoutes } from './core/enum/app-routes.enum';

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AppRoutes.USER,
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.HOME,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.STOCK_STATUS,
    loadChildren: () => import('./modules/stock/stock.module').then((m) => m.StockModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.ASSIGNMENT,
    loadChildren: () => import('./modules/adressage/adressage.module').then((m) => m.AdressageModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.COUNTING,
    loadChildren: () => import('./modules/counting/counting.module').then((m) => m.CountingModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.PICKING,
    loadChildren: () => import('./modules/picking/picking.module').then((m) => m.PickingModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.RECEIPT,
    loadChildren: () => import('./modules/receipt/receipt.module').then((m) => m.ReceiptModule),
    canLoad: [AuthGuard],
  },
  {
    path: AppRoutes.RETURN,
    loadChildren: () => import('./modules/return/return.module').then((m) => m.ReturnModule),
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: AppRoutes.HOME,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
